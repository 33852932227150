<template>
  <van-popup
    v-model="showPicker"
    class="fill-in-wrap-pop"
    position="right"
    closeable
    style="width: 100%; height: 100%"
  >
    <div class="fill-in-wrap">
      <div class="head">
        {{ blockName }}
      </div>
      <div class="content">
        <template v-for="(field, index) in fields">
          <van-field
            v-if="enterFields.includes(field.type)"
            :key="index"
            :type="field.type == 'amount' ? 'number' : field.type"
            v-model.trim="form[field.key]"
            :label="field.label"
            :placeholder="`请输入`"
            :rules="field.required ? required : []"
            :required="field.required"
            :disabled="injKeys.includes(field.key)"
            input-align="right"
            @change="field.change ? field.change : void 0"
            @blur="
              upperCaseKeys.includes(field.key) ? upperCase(field.key) : void 0
            "
          />
          <van-field
            v-if="selectFields.includes(field.type)"
            :key="index"
            v-model="texts[field.key]"
            :label="field.label"
            :placeholder="`请选择`"
            :rules="field.required ? required : []"
            :required="field.required"
            input-align="right"
            :disabled="injKeys.includes(field.key)"
            readonly
            is-link
            @click="anchorEnums(field.type, field.key)"
          />
        </template>
      </div>
      <div class="btns">
        <van-button size="small" @click="showPicker = false">取消</van-button>
        <van-button type="info" size="small" @click="saveFn">保存</van-button>
      </div>
    </div>
    <PopupPicker
      v-model="pickerView"
      :columns="pickerColumns"
      @confirm="pickerConfirm"
    />
    <PopupDatePicker
      v-model="datePickerView"
      @confirm="datePickerConfirm"
      :config="{
        minDate: new Date('1970-01-01')
      }"
    />
    <PopupRegionPicker
      v-model="regionPickerView"
      @confirm="regionPickerConfirm"
      :keys="keys"
    />
  </van-popup>
</template>
<script>
import { getAllEnums, fieldsFillSubmit } from "@/api";
import BLOCKFIELDS from "./ComputedFormFieldsv2";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    blockKey: {
      typ: String,
      default: "",
      required: true
    },
    blockName: {
      typ: String,
      default: "",
      required: true
    },
    blockFields: {
      type: [Array],
      default: () => [],
      required: true
    },
    // 需要从外部引入的值
    injectData: {
      type: Array,
      default: () => []
    },
    // 外部需要的key
    cacheKeys: {
      type: Array,
      default: () => []
    },
    // 需要大写处理的字段
    upperCaseKeys: {
      type: Array,
      default: () => []
    }
  },
  components: {
    PopupPicker: () => import("./PopupPicker.vue"),
    PopupDatePicker: () => import("./PopupDatePicker.vue"),
    PopupRegionPicker: () => import("./PopupRegionPicker.vue")
  },
  watch: {
    showPicker(v) {
      if (!v) {
        this.form = {};
        this.texts = {};
      } else {
        this.init();
      }
    },
    "form.marital_status"(v) {
      if (v == "20") {
        const itemname = this.blockFields.find(
          (item) => item.fieldAttributeName == "marital_name"
        );
        const nameShow = itemname?.status == "Y";

        const itemmob = this.blockFields.find(
          (item) => item.fieldAttributeName == "marital_mobile"
        );
        const mobShow = itemmob?.status == "Y";

        if (nameShow) {
          const nameReq = itemname?.required == "Y";
          this.fields.push({
            key: "marital_name",
            label: "配偶姓名",
            maxlength: 32,
            required: nameReq,
            type: "text"
          });
        }
        if (mobShow) {
          const mobReq = itemmob?.required == "Y";
          this.fields.push({
            key: "marital_mobile",
            label: "配偶电话",
            maxlength: 11,
            required: mobReq,
            type: "text"
          });
        }
      } else {
        ["marital_name", "marital_mobile"].map((key) => {
          let index = this.fields.findIndex((e) => e.key == key);
          if (index > 0) this.fields.splice(index, 1);
          this.$set(this.form, key, "");
        });
      }
    }
  },
  computed: {
    showPicker: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    },
    injKeys() {
      return this.injectData.map((e) => e.key);
    }
  },
  data() {
    return {
      required: [
        {
          required: true
        }
      ],
      // 输入字段类型
      enterFields: ["text", "textarea", "number", "amount"],
      // 选择字段类型
      selectFields: ["date", "select", "region", "datetime", "radio"],
      // 渲染字段
      fields: [],
      pickerView: false,
      // 所有枚举
      allEnums: {},
      bizNo: "",
      form: {},
      // testkey: false, // 本地测试用
      texts: {},
      keys: [], // 区域codes的key
      namekeys: [], // 区域name的key
      currentKey: "",
      pickerColumns: [],
      datePickerView: false,
      regionPickerView: false
    };
  },
  created() {
    this.getAllEnums();
  },
  methods: {
    async getAllEnums() {
      try {
        const { data } = await getAllEnums();
        this.allEnums = data;
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // test auto input
    // 本地测试用
    // testAutoInput(fields) {
    //   this.testkey = true; // 提交信息 不校验
    //   fields.map((e) => {
    //     this.$set(this.form, e.key, `测${e.label}`);
    //     this.$set(this.texts, e.key, `测${e.label}`);
    //   });
    // },
    // 输入后将某些字段改成大写
    upperCase(key) {
      this.form[key] = this.form[key].toLocaleUpperCase();
    },
    init() {
      const key = this.blockKey;
      const cachesFields = BLOCKFIELDS[key];

      const data = localStorage.getItem("_app_cache_data_") || "{}";

      this.cacheData = JSON.parse(data);
      let fields = [];
      this.bizNo = this.cacheData.bizNo;
      console.log(this.blockFields);
      this.blockFields.map((e) => {
        if (e.status == "Y") {
          let item = cachesFields[e.fieldAttributeName];
          if (this.injKeys.includes(e.fieldAttributeName)) {
            // 外部值填写表单中对应的字段
            const value = this.injectData.find(
              (obj) => obj.key == e.fieldAttributeName
            )?.value;
            this.$set(this.form, e.fieldAttributeName, value || "");
          }
          // e.value
          else if (e.fieldAttributeName == "used_month") {
            let v = +e.value / 12;
            v = v % 1 > 0 ? v.toFixed(1) : v;
            this.$set(this.form, e.fieldAttributeName, v || "");
          } else this.$set(this.form, e.fieldAttributeName, e.value || "");

          item.required = e.required == "Y";
          if (
            !["marital_name", "marital_mobile"].includes(e.fieldAttributeName)
          ) {
            this.$set(this.texts, e.fieldAttributeName, e.textvalue || "");
            fields.push(item);
          }
        }
      });
      this.fields = fields;
    },
    // 枚举的 与 字段的关键key 是对应关系
    // fieldAttributeName: 'enums_xxx' => 枚举 enums_xxx: []
    anchorEnums(type, key) {
      this.currentKey = key;
      if (["date", "datetime"].includes(type)) {
        this.datePickerView = true;
        return;
      }
      if (["region"].includes(type)) {
        this.regionPickerView = true;
        this.keys = ["provinceCode", "cityCode", "regionCode"];
        this.namekeys = ["provinceName", "cityName", "regionName"];
        return;
      }
      const enums = this.allEnums[key];
      this.pickerColumns = enums
        ? enums.map((e) => {
            return {
              text: e.value,
              value: e.key
            };
          })
        : [
            // {
            //   text: "测试数据1",
            //   value: 1
            // },
            // {
            //   text: "测试数据2",
            //   value: 2
            // }
          ];
      this.pickerView = true;
    },
    pickerConfirm({ value, text }) {
      this.$set(this.texts, this.currentKey, text);
      this.$set(this.form, this.currentKey, value);
    },
    datePickerConfirm(date) {
      this.$set(this.texts, this.currentKey, date);
      this.$set(this.form, this.currentKey, date);
    },
    regionPickerConfirm(codes, names) {
      console.log(codes);
      this.keys.map((_key, index) => {
        this.$set(this.form, _key, codes[index]);
      });
      this.namekeys.map((_key, index) => {
        this.$set(this.form, _key, names[index]);
      });
      let regionsText = names.join("/");
      this.$set(this.form, this.currentKey, codes[codes.length - 1]);
      this.$set(this.texts, this.currentKey, regionsText);
    },
    // 校验
    checkedFields() {
      const fields = this.fields;
      let unFill = []; // 必填未填写的字段
      let errMsg = []; // 未通过正则校验的字段
      const pass = fields.every((field) => {
        let v1 = true, // 必填通过
          v2 = true; // 正则通过
        if (field.required) {
          const val = this.form[field.key];
          if (!val) {
            v1 = false;
          }
          if (!v1) unFill.push(field.label);
        }
        if (field.validator) {
          try {
            field.validator(this.form[field.key], field.label);
            v2 = true;
          } catch (error) {
            v2 = false;
            errMsg.push(error);
          }
        }
        return v1 && v2;
      });
      return { pass, unFill, errMsg };
    },
    async saveFn() {
      try {
        const { pass, unFill, errMsg } = this.checkedFields();
        if (!pass) {
          // 未通过 且需要校验的情况
          if (unFill.length) throw `${unFill[0]}不可为空`;
          if (errMsg.length) throw errMsg[0];
        }

        this.blockFields.map((e) => {
          if ("used_month" == e.fieldAttributeName) {
            this.form[e.fieldAttributeName] = Math.floor(
              (+this.form[e.fieldAttributeName] || 0) * 12
            );
          }
          e.value = this.form[e.fieldAttributeName];

          e.textvalue = this.texts[e.fieldAttributeName];
        });
        const params = {
          bizNo: this.bizNo,
          qualificationKey: this.blockKey,
          map: this.form
        };
        console.log(params);
        await fieldsFillSubmit(params);
        this.showPicker = false;

        let cacheData = {};
        // 将内部某些值提供给外部使用
        console.log("this.cacheKeys", this.cacheKeys);
        if (this.cacheKeys && this.cacheKeys.length)
          this.cacheKeys.map((e) => {
            cacheData[e] = this.form[e];
          });
        console.log("this.cacheKeys", this.cacheKeys);
        // 向外部更新数据
        this.$emit("updateData", this.blockKey, this.form, cacheData);
      } catch (error) {
        this.$errMsg(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.fill-in-wrap-pop {
  :deep(.fill-in-wrap) {
    height: 100%;
    display: flex;
    flex-direction: column;
    .head {
      height: 96px;
      text-align: center;
      line-height: 96px;
      font-size: 32px;
      font-weight: 600;
      color: #333;
      border-bottom: 1px solid #efefef;
    }
    .content {
      flex: 1;
      overflow-y: auto;
    }
    .btns {
      border-top: 1px solid #efefef;
      height: 112px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      box-shadow: 0 -5px 10px #efefef;
      .van-button {
        min-width: 160px;
      }
    }
  }
}
</style>
